<template>
  <div id="app">
   <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style lang="less">
#app {
  width: 100vw;
  min-height: 100vh;
  background-color: #14171c;
}
</style>
