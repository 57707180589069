import Vue from 'vue'
import App from './App.vue'
import './assets/style/style.css'
import ElementUI from 'element-ui';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import 'element-ui/lib/theme-chalk/index.css';
import router from './router'
import "echarts";
import ECharts from 'vue-echarts'
import store from './store'

Vue.prototype.$store = store

Vue.use(VueAwesomeSwiper)

Vue.use(ElementUI);

Vue.config.productionTip = false

Vue.component('v-chart', ECharts)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
