import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

// 处理路由跳转当前页面报错问题
// const routerPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//   return routerPush.call(this, location).catch((err) => err)
// }

const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    component: () => import('../pages/home/index.vue'),
  },
  {
    path: '/info',
    component: () => import('../pages/info/index.vue'),
  },
]

var router = new VueRouter({
  mode: 'history',
  routes,
})

export default router
